import styled from 'styled-components';
import { theme } from '@styles';

const { colors, fonts } = theme;

export const BreadCrumbSection = styled.section`
  width: 100%;
  height: auto;
  padding: 2rem 0;
  background-color: ${colors.hollow};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${colors.text};
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;
  margin-bottom: 0.2em;
`;

export const Page = styled.ol`
  line-height: 1.6;
  justify-content: center;
  margin: 0;
  color: inherit;
  font-family: ${fonts.Secondary};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 32px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  li {
    display: inline-block;
    float: none;
    padding: 0;
  }
`;

export const Item = styled.li`
  a {
    color: ${colors.light};
    text-decoration: none;

    :hover {
      color: ${colors.accent};
    }
  }
`;

export const ActiveItem = styled.li`
  color: ${colors.accent};
  opacity: 0.9;
  padding-left: 0.5rem;

  :before {
    color: inherit;
    padding: 0 12px;
    content: '/';
  }
`;
